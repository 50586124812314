import EventEmitter from './EventEmitter.js'

export default class Scroll extends EventEmitter
{
    constructor()
    {
        super()
        this.scrollY = window.scrollY;

        // Scroll event
        this.scroll = this.scroll.bind(this)
        window.addEventListener('scroll', this.scroll)
    }

    /**
     * Scroll
     */
       scroll()
       {
            this.scrollY = window.scrollY;
            this.trigger('scroll')
       }

       getScrollY(){
           return this.scrollY;
       }
}
